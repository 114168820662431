import { Box, Heading } from "@chakra-ui/react"
import React from "react"
import { Articles } from "components"

const ArticlesPage = () => {
  return (
    <Box py={20}>
      <Articles />
    </Box>
  )
}

export default ArticlesPage
